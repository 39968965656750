@import "~theme/variables";

.container {
    padding-left: 0;
    padding-right: 0;
}

.sliderContainer {
    padding: 0;

    :global(.slick-track) {
        margin: 0;
    }
    :global(.slick-slider) {
        padding-bottom: $space-xxl;
    }
}

.content {
    margin-bottom: $space-l;
}

.description {
    > div {
      padding-bottom: 0;
    }
}